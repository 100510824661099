import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subject, Subscription } from 'rxjs';
import { INavData } from '../../_nav';
import { initializePendo } from '../../../assets/scripts/pendo';
import { AppAuthService } from '../../app-auth.service';
import { AppSessionService } from '../../app-session.service';
import { DataService } from '../../data-services/data.service';
import { ISimpleLead } from '../../interfaces/Lead';
import { HttpErrorResponse } from '@angular/common/http';
import { IErrorLog } from '../../interfaces/ErrorLog';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { debounceTime, delay, map, takeUntil } from 'rxjs/operators';
import { FeatureFlags } from 'src/app/interfaces/FeatureFlag';
import { Auth0Service } from 'src/app/auth0.service';
import { Auth0Roles } from 'src/app/interfaces/roles-enum';
import { AppSignalrService } from 'src/app/app-signalr.service';
import { ISignalRNotification } from 'src/app/interfaces/SignalR';
import { AppSettingsService } from 'src/app/data-services/app-settings.service';
import { Auth0Settings } from 'src/app/interfaces/AppSettings';
import { SnackbarComponent } from '../snack-bar/snack-bar.component';
declare global {
  interface Window {
    sloHelper: any;
  }
}

const BACK_ICON = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M11.953 15.9999L19.4914 8.46143L20.8965 9.86653L14.7632 15.9999L20.8965 22.1332L19.4914 23.5383L11.953 15.9999Z"
    fill="white" />
</svg>`;

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  searchInput = new FormControl();

  public navItems: INavData[];
  public isSearching = false;
  public searchCount = 0;
  public itemList: ISimpleLead[] = [];
  public navigationList: INavData[] = [];
  signalRNotificationsHandler: Subscription;
  isNavBarOpen: boolean = true;
  showInput: boolean = false;
  destroyed = new Subject<void>();
  isSmallDevice: boolean = false;
  isWebView: boolean = false;
  isTabView: boolean = false;
  settings: Auth0Settings;
  isAuth0Migrated: boolean = false;
  mode = new FormControl('side' as MatDrawerMode);
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Tablet, 'Tablet'],
    [Breakpoints.TabletLandscape, 'TabletLandscape'],
    [Breakpoints.TabletPortrait, 'TabletPortrait'],
    [Breakpoints.Web, 'Web'],
    [Breakpoints.WebLandscape, 'WebLandscape'],
    [Breakpoints.WebPortrait, 'WebPortrait'],
  ]);

  constructor(
    private signalRService: AppSignalrService,
    private authService: AppAuthService,
    private readonly menu: AppSessionService,
    private snackBar: MatSnackBar,
    private service: DataService,
    private router: Router,
    private auth0Service: Auth0Service,
    private elementRef: ElementRef,
    iconRegistry: MatIconRegistry, breakpointObserver: BreakpointObserver, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('backIcon', sanitizer.bypassSecurityTrustHtml(BACK_ICON));
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.Tablet,
        Breakpoints.TabletLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.Web,
        Breakpoints.WebLandscape,
        Breakpoints.WebPortrait,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            const name = this.displayNameMap.get(query);
            const _self = this;
            if (name != null) {
              _self.actionHandler[name](_self, result.matches);
            }
          }
        }
      });
    this.checkScreenSize();

    if (this.menu.getMenu() !== null) {
      this.navItems = this.menu.getMenu();
    } else {
      this.getMenu();
    }

    this.searchInput.valueChanges.pipe(
      debounceTime(500),
      map(() => {
        this.toggleSearchResultContainer(false);
        if (this.searchInput.value.length >= 3) {
          this.getDataSearchData(
            this.authService.AccountMember.AccountId,
            this.searchInput.value.trim()
          );
        } else {
          this.isSearching = false;
        }
        return this.itemList
      })
    ).subscribe({
      next: (value: ISimpleLead[]) => {
      }
    })
    this.settings = AppSettingsService.Settings.auth0Settings;
  }

  actionHandler = {
    'Small': this.smallDeviceChanges,
    'XSmall': this.smallDeviceChanges,
    'Tablet': this.tabletView,
    'TabletLandscape': this.tabletView,
    'TabletPortrait': this.tabletView,
    'Medium': () => {
      this.isWebView = true;
      this.isSmallDevice = false;
      this.mode.patchValue('side' as MatDrawerMode);
    },
    'Web': this.webView,
    'WebLandscape': this.webView,
    'WebPortrait': this.webView
  }

  public smallDeviceChanges(self: any, matcher: boolean = null) {
    self.isSmallDevice = matcher;
    self.isWebView = false;
    self.isTabView = false;
    self.isNavBarOpen = false;
    self.showInput = false;
    self.mode.patchValue('over' as MatDrawerMode);
    self.router.events.pipe(delay(500)).subscribe({
      next: (val: any) => {
        if (val instanceof NavigationEnd && self.isSmallDevice) {
          if (!self.isTabView || !self.webView) {
            self.isNavBarOpen = false;
          }
        }
      }
    })
  }

  private tabletView(self: any, matcher: boolean = null) {
    self.isSmallDevice = matcher;
    self.isWebView = false;
    self.isTabView = true;
    self.isNavBarOpen = true;
    self.showInput = true;

    self.mode.patchValue('side' as MatDrawerMode);
  }

  private webView(self: any, matcher: boolean = null) {
    self.isSmallDevice = false;
    self.isWebView = true;
    self.isTabView = false;
    self.isNavBarOpen = true;
    self.showInput = true;
  }


  public getMenu() {
    this.service.getMenu().subscribe(
      (data) => {
        console.log(data);
        this.navigationList = data;
        this.getFlagEnabledForAccount().subscribe(flag => {
          if (flag) {
            this.navigationList = data.filter(nav => nav.name !== 'Logout');
            const isNextechPM = this.authService.Account.PmsEmrId === 3;
            // if organization has PM role and integrated with PracticePlus only
            if (isNextechPM && this.auth0Service.hasRole(Auth0Roles.PmRole)) {
              const orgId = localStorage.getItem('auth0OrgId');
              const pmNavItem: INavData = {
                name: 'Practice Management',
                icon: 'icon-uam_pm',
                url: this.settings.practiceManagementBaseUrl + orgId
              };
              this.navigationList.push(pmNavItem);
            }
          }
          this.navItems = this.navigationList;
          this.menu.setMenu(this.navItems);
        });
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
        } else {
          console.log(err);
        }
      },
      () => { }
    );
  }

  toggleInput(): void {
    this.showInput = !this.showInput;
  }

  public cancelSearch() {
    this.itemList = [];
    this.searchCount = 0;
    this.searchInput.setValue('');
  }

  public useThisClick(item: ISimpleLead) {
    this.useThis(item.Id);
    this.itemList = [];
    this.searchCount = 0;
    this.searchInput.setValue('');
  }

  public getDataSearchData(accountId: number, text: string) {
    this.isSearching = true;
    const l1 = this.service.getSimpleLeadSearch(accountId, text);
    l1.subscribe(
      (data) => {
        this.isSearching = false;
        this.searchCount = 0;
        this.itemList = [];
        if (data !== null) {
          this.searchCount = data.length;
          this.itemList = data;
          this.toggleSearchResultContainer(true);
        } else {
          this.itemList = [];
          this.searchCount = 0;
          this.toggleSearchResultContainer(false);
        }
      },
      (err) => {
        this.isSearching = false;
        if (err instanceof HttpErrorResponse) {
          const error: IErrorLog = err.error;
          console.log(error);
        } else {
          console.log(err);
        }
      },
      () => { }
    );
  }

  toggleSearchResultContainer(status: boolean) {
    this.elementRef.nativeElement.ownerDocument.body.ownerDocument.body.querySelector(".cdk-overlay-pane").style.display = status ? "flex" : "none";
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkScreenSize();
  }

  private checkScreenSize(): void {
    if (window.innerWidth > 767) {
      this.showInput = true;
    }
  }

  useThis(id: number) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: id,
      },
    };

    this.router.navigate(
      ['../lead-management/lead-details/'],
      navigationExtras
    );
  }

  allLeads() {
    this.router.navigate(
      ['../lead-management/all-leads']
    );
  }

  createManualLead() {
    this.router.navigate(['../lead-management/manual-lead']);
  }

  ngAfterViewInit() {
    this.searchItem(this.router.url, this.navItems);
  }

  searchItem(url: string, items: INavData[]): void {
    if (items) {
      items.forEach(element => {
        if (element.children) {
          this.searchItem(url, element.children);
        }
      });
    }
  }

  ngOnInit() {

    this.signalRNotificationsHandler = this.signalRService.signalRChange.subscribe(
      (notification) => this.ProcessSignalRNotifications(notification)
    );

    if (window.pendo) {
      initializePendo(this.authService.Account, this.authService.AccountMember, this.authService.getClientId());
    }

    const storedStatus = sessionStorage.getItem('isNavBarOpen');
    if (storedStatus !== null) {
      this.isNavBarOpen = JSON.parse(storedStatus);
    }

    if (sessionStorage.getItem("auth0SessionId")) {
      this.loadSlo();
      this.isAuth0Migrated = true;
    }

  }

  public ProcessSignalRNotifications(notification: ISignalRNotification) {
    this.snackBar.openFromComponent(SnackbarComponent,{
      data: {
        title: notification.title,
        content: notification.content
      } , duration: 3000
    });
  }

  loadSlo() {
    this.loadScript(
      'https://nextech-systems-intellechartpro-static-assets.azureedge.net/page/login-redirect/2.0.0.178/s/microsoft-signalr_7.0.5_signalr.min.js',
      'sha384-7P6nEkUGrrFPlipde9n0iiEH8jVX7H/enbGKMOfFo9c+nALvVAtJTgCXZjF6G+cf',
      'anonymous'
    );

    const sloOptions = {
      auth0SignalRUrl: this.settings.auth0SignalRUrl,
      auth0ApiUrl: this.settings.auth0ApiBaseUrl,
      logoutUrl: this.settings.appUri + `/auth0/logged-out?logout=true&organization=${localStorage.getItem('auth0OrgId')}`,
      appName: this.settings.appName,
      logoutEventCallback: null,
      notificationCallback: null,
      orgChangingCallback: this.conditionalClose,
      appLoginUrl: this.settings.appUri + '/auth0',
      universalLoginUrl: this.settings.universalLoginUrl,
      forceCloseTabsOnOrgChange: false
    };

    const onLoadCallback = sessionStorage.getItem("auth0SessionId")
      ? () => {
        setTimeout(() => {
          window.sloHelper.init(sloOptions);
        }, 100);
      }
      : undefined;

    this.loadScript(
      'https://nextech-systems-intellechartpro-static-assets.azureedge.net/page/login-redirect/2.0.0.178/s/sloHelper.js',
      'sha384-ex24D0eII3Vuv4WUPfiRaSAN/k4xP5w/MXDCUvWnT+s1girbNRnJ6BqBkYkLm+eY',
      'anonymous',
      onLoadCallback
    );
  }

  toggleSidenav(): void {
    this.isNavBarOpen = !this.isNavBarOpen;
    this.saveStatusToSessionStorage();
  }

  openedStart() {
    this.isNavBarOpen = true;
    this.saveStatusToSessionStorage();
  }

  closedStart() {
    this.isNavBarOpen = false;
    this.saveStatusToSessionStorage();
  }

  loadScript(src: string, integrity: string, crossOrigin: string, onLoadCallback?: () => void): void {
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', src);
    scriptElement.setAttribute('integrity', integrity);
    scriptElement.setAttribute('crossOrigin', crossOrigin);
    if (onLoadCallback) {
      scriptElement.onload = onLoadCallback;
    }
    document.body.appendChild(scriptElement);
  }

  private saveStatusToSessionStorage() {
    sessionStorage.setItem('isNavBarOpen', JSON.stringify(this.isNavBarOpen));
  }

  ngOnDestroy() {
    this.signalRNotificationsHandler.unsubscribe();
    this.snackBar.dismiss();
    this.destroyed.next();
    this.destroyed.complete();
  }

  getFlagEnabledForAccount() {
    return this.service.getFlagEnabledForAccount(FeatureFlags.auth0Migrated);
  }

  conditionalClose() {
    // Define the prefixes to keep
    const prefixesToKeep = ["a0", "auth0", "org"];
    // Iterate over all keys in session storage
    for (let key of Object.keys(sessionStorage)) {
      // Check if the key does not start with any of the prefixesToKeep
      const startsWithAnyPrefix = prefixesToKeep.some(prefix => key.startsWith(prefix));
      // If the key does not start with any of the specified prefixes, remove it
      if (!startsWithAnyPrefix) {
        sessionStorage.removeItem(key);
      }
    }

    if (window.location.href.toLowerCase().indexOf("/#/dashboard") > -1) {
      return true;
    } else {
      return false;
    }
  }
}
